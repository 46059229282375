<template>
  <div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      keyWord: "", //搜索关键字
      tableData: [],
      total: 0,
      currentPageNum: 1,
      pageSize: 6,
      activeName: "0",
      src: require("@/assets/404_images/no_data.png"),
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
    }),
  },
  created() {
    this.getDataList();
  },
  methods:{}
};
</script>
<style lang="scss">
.collection-container {
  // padding: 20px 30px;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  .col-nav {
    width: 100%;

    .el-tabs {
      .el-tabs__header {
        // background: #333;
        .el-tabs__nav {
          height: 50px;
        }

        .el-tabs__item {
          height: 26px;
          // color:#2d81ff;
          font-family: Microsoft YaHei;
          font-size: 16px;
          font-weight: bold;
          line-height: 45px;
        }

        .el-tabs__active-bar {
          width: 81px;
          height: 3px;
          background-color: #2d81ff;
        }
      }
    }

    .img {
      width: 80%;
      // background: red;
    }
  }
}
</style>
